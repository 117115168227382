@font-face {
    font-family: 'AvenirNextLTPro-Regular';
    src: url('http://anthillrealtors.co.id/wp-content/themes/x-child/fonts/AvenirNextLTPro-Regular.eot');
    src: url('http://anthillrealtors.co.id/wp-content/themes/x-child/fonts/AvenirNextLTPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('http://anthillrealtors.co.id/wp-content/themes/x-child/fonts/AvenirNextLTPro-Regular.woff') format('woff'),
    url('http://anthillrealtors.co.id/wp-content/themes/x-child/fonts/AvenirNextLTPro-Regular.ttf') format('truetype'),
    url('http://anthillrealtors.co.id/wp-content/themes/x-child/fonts/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular') format('svg');
}

* {
    font-family: "AvenirNext","Helvetica Neue",Helvetica,Arial,Poppins,sans-serif;
}

@media print {
    * {
        break-inside: avoid;
    }

    a {
        color: inherit;
    }
}

body {
    padding: 0;
    margin: 0;
}

.root {
    min-height: 100vh;
    min-width: 100vw;
    background-color: #fbffff;
}

a {
    color: #0069d9;
}
